.contentWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    height: auto;
    width: auto;
}

.select {
    font-size: large;
    width: 100%;
    text-align: center;
}

.modalWindow {
    width: auto;
    position: relative;
}

.coll {
    margin-left: 5px;
}

.container__options{
    margin-left: 50%;
}

.options__block {
    margin: 15px 0;
}

.label {
    width: 100%;
    font-size: larger;
    color: #4d4d4d;
    padding-bottom: 15px;
}
