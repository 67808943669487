.showingDepartmentName {
    color: black;
    display: flex;
    align-items: center;
    margin: 0;
    line-height: 16px;
}

.changingDepartment {
    margin-left: 20px;
}

.menu {
    display: flex;
    align-items: center;
    padding: 4px 35px 0 0;
    border: none;
    font-size: 16px;
    margin: 0 0 0 auto;
    height: 100%;
}

.logoutButton {
    margin-top: 8px;
    font-size: 16px;
}

.textContainer {
    min-width: 288px;
    line-height: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
}

.mainText {
    height: auto;
    margin: 0 0 2px;
    font-weight: bold;
    font-size: 17px;
}

.additionalText {
    height: auto;
    margin: 0;
    color: #8c8c8c;
    font-size: 12px;
}

.currentDepartmentTitle {
    height: auto;
    margin: 0;
    font-size: 16px;
}

.logoImage {
    margin: 3px 25px 0 0;
}

@media screen and (max-width: 1462px) {
    .mainText {
        font-size: 15px;
    }
}

@media screen and (max-width: 1239px) {
    .mainText {
        display: none;
    }
    .additionalText {
        display: none;
    }
}