.integerInput {
    height: 40px;
    text-align: center;
    font-size: large;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    height: auto;
}

.select {
    font-size: large;
    width: 100%;
    text-align: center;
}

.modalWindow {
    width: 500px;
    position: relative;
}

.label {
    width: 100%;
    font-size: larger;
    color: #4d4d4d;
    padding-bottom: 15px;
}

.localNotification {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: large;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: solid 1px #d9d9d9;
    margin: 10px 0 15px 0;
    text-align: center;
    padding: 10px 0;
}

.switcherWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: larger;
    color: #4d4d4d;
    padding: 5px 0 20px;
}