@keyframes arrow {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.arrow {
    animation: arrow 1s infinite linear;
    transform-origin: center;
    transform-box: fill-box;
}

.loaderBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
    z-index: 9995;
    backdrop-filter: blur(2px);
}

.loaderWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
