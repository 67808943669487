.riseArrow {
    color: #52C41A;
}

.riseArrow :first-child {
    height: 1.5em;
    width: 1.5em;
}

.fallArrow {
    color: #F5222D;
}

.fallArrow :first-child {
    height: 1.5em;
    width: 1.5em;
}

.dynamicStatisticElement {
    position: absolute;
    top: 34px;
    right: 0;
    display: flex;
    align-items: center;
}