body {
  max-height: 100vh;
}

.container {
  width:1400px;
  height: auto;
  margin: 10px auto;
}

.ant-layout-footer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  position: relative;
  bottom: 0;
}

.ant-layout-footer *{
  flex-grow: 1;
}

.ant-layout-content {
  max-height: 100vh;
  overflow: auto;
}

.ant-layout-header {
  background-color: white;
  height: 72px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  position: sticky;
  top: 0
}

.ant-layout-header > *{
  margin: 10px;
}

.footerButton {
  margin: 0 10px;
}

.tableContent {
  height: calc(100% - 80px);
  overflow: auto;
}

.tableFooter {
  display: flex;
  align-content: center;
  height: 80px;
  padding: 10px 50px;
}

@media screen and (max-width: 692px) {
  .tableFooter {
    height: 160px;
  }
  .tableContent {
    height: calc(100% - 160px);
  }
}