.cardsContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

}

.card {
    margin: 6px;
    text-align: center;
}

.footerButton {
    margin: 10px;
}

.rowInfo {
    display: flex;
    justify-content: space-between;
}

.text {
    font-size: 16px;
}