* {
    box-sizing: border-box;
}

body {
    height: 75vh;
    background-color: #F0F2F5;
}

.loginFormWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 352px;
    height: 408px;
    min-width: 200px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(107, 138, 228, 0.1);
    background-color: white;
    margin: 24vh 20%;
}

.loginForm {
    width: 80%;
}

.loginFormForgot {
    float: right;
}

.loginFormCheckbox {
    float: left;
}

.loginFormButton {
    width: 100%;
}

.loginFormTitle {
    display: block;
    font-weight: bolder;
}

.loginFormItem {
    margin-bottom: 30px;
}

.infoIcon {
    margin-right: 5px;
}

.infoNotification {
    height: 40px;
    margin-top: 30px;
    font-size: 16px;
}

.siteTitleContainer {
    position: absolute;
    display: flex;
    align-items: center;
    width: 600px;
    left: 35vw;
}

.logoTextContainer {
    line-height: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
    margin-top: 14px;
}

.mainLogoText {
    height: 20px;
    margin: 0;
    font-weight: bold;
    font-size: 17px;
}

.additionalLogoText {
    height: 20px;
    padding: 11px 0;
    color: #8c8c8c;
    font-size: 12px;
}

.logoImage {
    margin: 0 25px 0 0;
}

.developerLogo {
 position: fixed;
    right: 2vw;
    top: 87vh;
}

@media screen and (max-width: 900px) {

    .loginFormWrapper {
        margin: 100px auto;
    }

    .siteTitleContainer {
        left: 0;
        top: -70px;
    }

    .mainLogoText {
        font-size: 15px;
        width: 261px;
    }

    .logoTextContainer {
        line-height: 18px;
    }

    .additionalLogoText {
        margin: 17px 0 0 0;
        font-size: 10px;
    }

    .logoImage {
        margin: 0 15px 0 15px;
    }

    .developerLogo {
        position: absolute;
        top: 440px;
        right: auto;
    }

}